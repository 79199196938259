import {
	readChartRatesData,
	readChartSeries,
	saveTimeLeftPeriodRange,
} from '../../infrastructure/store';

export const useUpdateChartSeriesRates = () => {
	return () => {
		const series = readChartSeries();
		const ratesData = readChartRatesData();

		if (!series) {
			return;
		}

		// series.setData(ratesData);

		const first = ratesData[0];
		if (first) {
			saveTimeLeftPeriodRange(first.time);
		}

		// const last = ratesData[ratesData.length - 1];
		// if (last) {
		//   series.setMarkers([
		//     {
		//       time: last.time,
		//       position: "inBar",
		//       color: "#F6D89B",
		//       shape: "circle",
		//       size: 1,
		//     },
		//   ]);
		// }
	};
};
